/*
 * @Author: 恐龙
 * @Date: 2022-09-24 14:51:34
 * @LastEditors: konglong
 * @LastEditTime: 2023-03-07 14:21:02
 * @FilePath: \new\src\main.js
 * @Description: WX:kl-life0812
 * 
 * 
 */
import Vue from "vue";
import App from "./App.vue";

import router from "./router"; //引入路由信息

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from 'element-ui/lib/locale/lang/en' 

import Video from "video.js";
import "video.js/dist/video-js.min.css";
Vue.prototype.$video = Video;

import * as directives from "@/directives"; //全部导入 全局注册自定义指令
// 注册自定义指令
// 遍历所有的导出的指令对象 完成自定义全局注册
// es6写法  key就是directives里面的所有的变量 imagerror
Object.keys(directives).forEach((key) => {
  // 注册自定义指令
  Vue.directive(key, directives[key]);
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});


router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

import axios from "axios";
Vue.prototype.$axios = axios;

// axios.defaults.baseURL = "http://192.168.0.188:8000";
axios.defaults.baseURL = "https://stock.etruckgo.com/api";

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
